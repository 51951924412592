import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
    const [blogId, setBlogId] = useState('');
    const [postUrl, setPostUrl] = useState('');
    const [blogScore, setBlogScore] = useState(null);
    const [postScore, setPostScore] = useState(null);

    const handleBlogSubmit = async () => {
      const response = await axios.post('https://trendspotter-backend-25a41be9f2e4.herokuapp.com/calculateBlogScore', { blogId });
      setBlogScore(response.data.score);
    };
  
    const handlePostSubmit = async () => {
        const response = await axios.post('https://trendspotter-backend-25a41be9f2e4.herokuapp.com/calculatePostScore', { postUrl });
        setPostScore(response.data.score);
    };
  

    return (
        <div className="App">
            <header className="App-header">
                <h1>TrendSpotter</h1>
            </header>
            <div className="form-container">
                <div className="form-section">
                    <h2>블로그 지수 계산</h2>
                    <input 
                        type="text" 
                        value={blogId} 
                        onChange={(e) => setBlogId(e.target.value)} 
                        placeholder="네이버 블로그 아이디" 
                    />
                    <button onClick={handleBlogSubmit}>계산하기</button>
                    {blogScore && <p>블로그 지수: {blogScore}</p>}
                </div>
                <div className="form-section">
                    <h2>포스팅 지수 계산</h2>
                    <input 
                        type="text" 
                        value={postUrl} 
                        onChange={(e) => setPostUrl(e.target.value)} 
                        placeholder="네이버 블로그 포스팅 주소" 
                    />
                    <button onClick={handlePostSubmit}>계산하기</button>
                    {postScore && <p>포스팅 지수: {postScore}</p>}
                </div>
            </div>
        </div>
    );
}

export default App;
